<template lang="pug">
  Auth(@loginFailed="loginFailed")
    div.info-wrapper
      div.info-container
        img(src="/img/logo.png")
        p.bold ご登録ありがとうございました！
        p.mb-0 お手数ですが、アプリの開発完了まで
        p LINEにて質問などお願いいたします。
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.info-wrapper {
  width: 100%;
  height: 100vh;
  padding-top: 10%;
  .info-container {
    text-align: center;
  }
  img {
    width: 240px;
    margin-bottom: 64px;
  }
}
</style>

<script>
import Auth from '@/components/shared/Auth'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth
  },
  computed: {
    ...mapStateAuth(['isLoggedIn', 'uid'])
  },
  methods: {
    loginFailed () {
      this.$router.push('/sign-in')
    }
  }
}
</script>
